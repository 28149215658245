import { CCol, CImage, CRow } from '@coreui/react-pro'
import axios from '@/shared/config/axios-interceptor'
import { useEffect, useState } from 'react'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

interface IProps {
  chatRoom: any
}

const Photo = ({ chatRoom }: IProps) => {
  const [info, setInfo] = useState([])
  const { params } = useRouter()

  const fetchPhoto = async () => {
    try {
      const res = await axios.get(
        `/message/room/${params?.roomId}?limit=50&sortBy=timestamp&sortOrder=DESC&roomId=${params?.roomId}&type=IMAGE`,
      )
      setInfo(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPhoto()
  }, [params?.roomId])

  return (
    <>
      <div className="px-2">
        <CRow className="g-1">

          <PhotoProvider>
            {info.map((item: any, index: number) => {
              return (<CCol xs={3} key={index}>
                <div className="cursor-pointer ratio ratio-1x1">
                  <PhotoView src={item.content}>
                    <CImage className="w-100 h-100 object-fit-cover ratio ratio-1x1" src={item.content} />
                  </PhotoView>
                </div>
              </CCol>
              )
            })}

          </PhotoProvider>
        </CRow>
      </div>
    </>
  )
}

export default Photo
