import ContentLeft from "./components/ContentLeft"
import HeaderLeft from "./components/HederLeft"

interface Iprops {
  renderAction: any
  setRenderAction: any
  searchMsgKey: any
  setSearchMsgKey: any
  listChatRef: any
}

const RenderAction = (props: Iprops) => {
  const { renderAction, setRenderAction, searchMsgKey, setSearchMsgKey, listChatRef } = props



  return <>
    <HeaderLeft renderAction={renderAction} setRenderAction={setRenderAction} searchMsgKey={searchMsgKey} setSearchMsgKey={setSearchMsgKey} />
    <ContentLeft renderAction={renderAction} setRenderAction={setRenderAction} listChatRef={listChatRef} />
  </>
}

export default RenderAction
