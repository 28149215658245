import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { RootState } from '../../../reducers'
import { useRouter } from '../../../shared/utils/hooks/useRouter'
import { AppDispatch } from '../../../store'
import { ToastError, ToastSuccess } from '../../shared/toast/Toast'
import { ILoginForm, login, register } from './auth.api'
import { fetching } from './auth.reducer'
import { NavLink } from 'react-router-dom'
import logoSms from '../../../assets/img/telegram/logo-sms.png'
import { AiOutlineEdit } from 'react-icons/ai'
import axios from '../../../shared/config/axios-interceptor';
import logo from '../../../assets/img/telegram/logo-tele1.png'


interface ILocationPath {
  path?: string
}

const RegisterByPhone = () => {
  const dispatch = useDispatch<AppDispatch>()

  const { navigate, location } = useRouter()
  const state = location.state as ILocationPath
  const { user, loading, loginSuccess, firstTimeLogin } = useSelector(
    (state: RootState) => state.authentication,
  )

  const [checkPhone, setCheckPhone] = useState<string>('')


  const [step, setStep] = useState<number>(0)

  const phoneNumberRegex = /^\d{7,15}$/;

  // Kiểm tra số điện thoại
  const testPhoneNumber = (number: string) => {
    return phoneNumberRegex.test(number);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .matches(phoneNumberRegex, 'Số điện thoại không hợp lệ')
      .required('Số điện thoại là bắt buộc'),
    code: Yup.string().required('Please enter code'),
  })

  useEffect(() => {
    if (user) {
      const redirectPath = state?.path || '/'
      navigate(redirectPath, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (loginSuccess && !firstTimeLogin) {
      ToastSuccess('Đăng nhập thành công')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess])

  const initialValues: ILoginForm = { username: '', password: '', repassword: '', code: '' }

  const [sendCode, setSendCode] = useState<boolean>(false)

  const handleInputChange = (event: any, setFieldValue: any) => {
    const { value } = event.target
    setFieldValue('username', value);

    if (testPhoneNumber(value)) {
      setCheckPhone('')
    } else {
      setCheckPhone('Phone number is not valid')
    }
  }

  const nexStep = (username: string) => {
    if (!username) {
      setCheckPhone('Please enter phone number')
      return
    }
    setStep(1)
  }

  const generateRandom6DigitNumber = () => {
    return Math.floor(100000 + Math.random() * 900000);
  }

  const checkOtp = async (username: string, otp?: string) => {
    const session = localStorage.getItem('session');
    if (session) {
      try {
        return await axios.post('/user/otp/check', { username, session: session, otp });
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleSendCode = async (setFieldValue: any, values: any) => {
    const otp = await getOtp(values.username)
    setSendCode(true)
    const a = setInterval(async () => {
      const data = await checkOtp(values.username, otp.otp)
      console.log(data);
      if (data?.data?.otp) {
        setSendCode(false)
        setFieldValue('code', data?.data?.otp)
        clearInterval(a)
      }
    }, 3000)
  }

  const getOtp = async (username: string) => {
    const session = Math.floor(100000 + Math.random() * 900000);
    localStorage.setItem('session', session.toString());
    try {
      const result = await axios.post('/user/otp', { username, session: session });
      return result.data
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div style={{ background: '#1f2020' }}>

      <div className='d-flex min-vh-100 align-items-center justify-content-center m-auto' style={{ maxWidth: 360 }}>
        <CRow className="align-items-center px-3" >
          <CCol xs={12}>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={async (values) => {
                const check = await checkOtp(values.username, values.code);
                if (!check?.data) {
                  ToastError('Mã OTP không chính xác')
                  return;
                }
                dispatch(fetching())
                values.password = '12345678'
                dispatch(register(values))
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <CForm onSubmit={handleSubmit} className="custom-form custom-form-register">

                  <CCol className='text-center mb-4'>
                    {
                      step === 0 ?
                        <div>
                          <img src={logo} style={{ maxWidth: 160, maxHeight: 160 }} />
                          <h1 className='mt-5' style={{ fontSize: 32, fontWeight: 500, color: '#fff' }}>
                            Security Chat
                          </h1>
                          <p style={{ color: "rgb(112,117,121)", margin: '0 auto' }} className='mb-5'>Please confirm your country code
                            and enter your phone number.</p>
                        </div>
                        :
                        <div>
                          <img src={logoSms} style={{ maxWidth: 160, maxHeight: 160 }} />
                          <h1 className='mt-5 d-flex gap-2 justify-content-center align-items-center' style={{ fontSize: 32, fontWeight: 500, color: '#fff' }}>
                            {values.username} <div onClick={() => setStep(0)}><AiOutlineEdit color='#fff' /></div>
                          </h1>
                          <p className='mb-5' style={{ color: "rgb(112,117,121)" }}>We've sent the code to the <b>Security Chat</b> app on your other device.</p>
                        </div>
                    }

                  </CCol>

                  <CRow className={"mb-3" + (step === 0 ? ' d-block' : ' d-none')}>
                    <CCol xs={12}>
                      <div className="box-custom-form-login">
                        <CFormLabel htmlFor='username'>Your phone number</CFormLabel>
                        <CFormInput
                          value={values.username}
                          onChange={(
                            e: React.FormEvent<HTMLInputElement>,
                          ) => handleInputChange(e, setFieldValue)}
                          type="text"
                          id="username"
                          name="username"
                          autoComplete="none"
                          onBlur={handleBlur}
                        />
                      </div>
                      <CFormFeedback className={checkPhone ? 'mt-2 d-block' : 'd-none'} style={{ color: 'red', fontSize: 12 }}>
                        {checkPhone}
                      </CFormFeedback>
                    </CCol>
                  </CRow>


                  <CRow className={"mb-3" + (step === 0 ? ' d-none' : ' d-block')}>
                    <CCol xs={12}>
                      <div className="box-custom-form-login">
                        <CFormLabel htmlFor='code'>Code</CFormLabel>
                        <CFormInput
                          value={values.code}
                          onChange={handleChange}
                          type="text"
                          id="code"
                          name="code"
                          autoComplete="none"
                          // placeholder="Nhập tên đăng nhập"
                          onBlur={handleBlur}
                        />
                      </div>
                    </CCol>
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.code && touched.code
                          ? 'd-block'
                          : 'd-none'
                      }
                    >
                      {errors.code}
                    </CFormFeedback>
                  </CRow>

                  <CRow className={"mb-3" + (step === 0 ? ' d-none' : ' d-block')}>
                    <CCol xs={12}>
                      <div onClick={() => handleSendCode(setFieldValue, values)} className="box-custom-form-login d-flex align-items-center gap-2" style={{ color: "rgb(64, 167, 228)", cursor: "pointer" }}>
                        Lấy mã tự động kích hoạt tài khoản
                        <div className={sendCode ? 'spinner-border' : 'd-none'} style={{ width: "1rem", height: "1rem" }}>
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </CCol>
                  </CRow>

                  {/* <CRow className="mb-3">
                    <CCol xs={12}>
                      <div className="box-custom-form-login">
                        <CFormLabel>Mật khẩu</CFormLabel>
                        <CFormInput
                          value={values.password}
                          onChange={handleChange}
                          type="password"
                          id="password"
                          name="password"
                          autoComplete="none"
                          placeholder="Nhập mật khẩu của bạn"
                          onBlur={handleBlur}
                        />
                      </div>
                    </CCol>
                  </CRow>

                  <CRow className="mb-3">
                    <CCol xs={12}>
                      <div className="box-custom-form-login">
                        <CFormLabel>Xác nhận mật khẩu</CFormLabel>
                        <CFormInput
                          value={values.repassword}
                          onChange={handleChange}
                          type="password"
                          id="repassword"
                          name="repassword"
                          autoComplete="none"
                          placeholder="Nhập mật khẩu của bạn"
                          onBlur={handleBlur}
                        />
                      </div>
                    </CCol>
                  </CRow> */}

                  <CRow className="m-0 justify-content-end">
                    {
                      step === 0 ?
                        <CButton style={{ background: "#40a7e4", color: "#000", borderRadius: 8, boxShadow: 'none' }}
                          className="primary-500 w-100 mt-3 py-2 text-uppercase text-white"
                          type="button"
                          disabled={loading}
                          onClick={() => nexStep(values.username)}
                        >
                          NEXT
                        </CButton> :
                        <CButton style={{ background: "#40a7e4", color: "#000", borderRadius: 8, boxShadow: 'none' }}
                          className="primary-500 w-100 mt-3 py-2 text-uppercase text-white"
                          type="submit"
                          disabled={values.code ? false : true}
                        >
                          NEXT
                        </CButton>
                    }


                  </CRow>


                  <CRow className="m-0 justify-content-end mt-5">
                    <NavLink to="/login" style={{ color: "rgb(64, 167, 228)", fontSize: 14 }}
                      className="primary-500 w-100 text-center text-decoration-none py-2 text-uppercase"
                    >
                      LOGIN
                    </NavLink>
                  </CRow>


                </CForm>
              )}
            </Formik>
          </CCol>
        </CRow>
      </div>

    </div>

  )
}

export default RegisterByPhone
