import { checkErrorInputFile } from '@/components/shared/AvatarUpload/AvatarUploadContainer'
import SModal from '@/components/shared/Modal/SModal'
import { ToastError } from '@/components/shared/toast/Toast'
import { handleUploadImage } from '@/shared/utils/ultils'
import { cisCameraPlus } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import {
    CAvatar,
    CForm,
    CFormInput,
    CFormLabel,
    CModalBody,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useState } from 'react'
import { socket } from '@/shared/config/socket'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { IChat, ICreateChatResponse } from '@/shared/model/chat.model'
import { AppDispatch } from '@/store'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import axios from '@/shared/config/axios-interceptor'
import { getEntities } from '../../chat.api'

interface Iprops {
    editGroup: any
    setEditGroup: any
    chatRoom: IChat
}
const ModalEditGroup = ({ editGroup, setEditGroup, chatRoom }: Iprops) => {
    const dispatch = useDispatch<AppDispatch>()
    const [step, setStep] = useState(0)
    const { user, createGroup } = useSelector(
        (state: RootState) => state.authentication,
    )

    const { navigate, params } = useRouter()

    const handleRemappingImageValue = async (value: any) => {
        if (value.fileAvatar) {
            const avatarUrlPromises = await handleUploadImage(value.fileAvatar)
            value.avatar = avatarUrlPromises
        }
        return value
    }

    const initialValues: any = {
        fileAvatar: null,
        avatar: '',
        name: null,
    }

    const handleFileUploadavatar = async (
        e: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
    ) => {
        const inputFiles = e.target.files
        if (inputFiles && inputFiles[0]) {
            if (!checkErrorInputFile([inputFiles[0]])) return
            const reader = new FileReader()
            reader.onload = (e) => {
                setFieldValue('fileAvatar', inputFiles[0])
                setFieldValue('avatar', e.target?.result)
            }
            reader.readAsDataURL(inputFiles[0])
        }
    }

    return (
        <SModal
            visible={editGroup}
            onClose={() => setEditGroup(false)}
            alignment="center"
        >
            <CModalBody>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={chatRoom || initialValues}
                        onSubmit={async (value, { resetForm }) => {
                            const values = await handleRemappingImageValue(value)

                            const roomData = {
                                roomId: chatRoom.id,
                                name: value.name,
                                avatar: values.avatar
                            }

                            try {
                                const data = await axios.post(`/room/update-name-and-avatar`, roomData);
                                if(data){
                                    setEditGroup(false)
                                    resetForm()
                                    dispatch(getEntities({page: 1, limit: 20, sortOrder: 'DESC', sortBy: 'lastModifiedDate' }))
                                }
                            } catch (error) {
                                console.log(error)
                            }

                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                            handleSubmit,
                            resetForm,
                            handleBlur,
                        }) => (
                            <CForm onSubmit={handleSubmit} className="form-add-group py-2">
                                <div className={'gap-4' + (step === 0 ? ' d-flex' : ' d-none')}>
                                    <div className="p-0 box-avatar">
                                        <div className="d-flex justify-content-center position-relative">
                                            <div className="profileImageContainer">
                                                <div className="position-relative box-select-file">
                                                    {values.avatar ? (
                                                        <CAvatar
                                                            color="secondary"
                                                            className="avatar-xxl object-fit-cover avatar-54"
                                                            src={values.avatar}
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                    ) : (
                                                        <div
                                                            className="d-flex flex-column justify-content-center align-items-center"
                                                            style={{ width: '100%', height: '100%' }}
                                                        >
                                                            <CIcon
                                                                icon={cisCameraPlus}
                                                                className="mb-3"
                                                                style={{
                                                                    width: '33px',
                                                                    height: '33px',
                                                                    color: '#fff',
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <CFormLabel
                                                    htmlFor="custom-task-file-input-1"
                                                    className={`p-image m-0`}
                                                >
                                                    {values.avatar ? (
                                                        <CIcon
                                                            icon={cisCameraPlus}
                                                            className="upload-button"
                                                            style={{ color: '#fff' }}
                                                        />
                                                    ) : (
                                                        <div className="add-image"></div>
                                                    )}
                                                    <input
                                                        id="custom-task-file-input-1"
                                                        className="file-upload"
                                                        type="file"
                                                        onClick={(e) => (e.currentTarget.value = '')}
                                                        onChange={(e) =>
                                                            handleFileUploadavatar(e, setFieldValue)
                                                        }
                                                        accept="image/*"
                                                    />
                                                </CFormLabel>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100">
                                        <CFormLabel
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: 'rgb(64, 167, 228)',
                                            }}
                                        >
                                            Group name
                                        </CFormLabel>
                                        <CFormInput className='border-0 border-bottom border-2 rounded-0 px-0 text-white' style={{ boxShadow: "none", background: "transparent" }}
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <div className="d-flex justify-content-end gap-4">
                                        <div
                                            className="cursor-pointer"
                                            style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                                            onClick={() => setEditGroup(false)}
                                        >
                                            Cancel
                                        </div>
                                        <div
                                            className="cursor-pointer"
                                            style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                                        >
                                            <button
                                                type="submit"
                                                className="border-0 bg-transparent "
                                                style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </CForm>
                        )}
                    </Formik>
                </div>
            </CModalBody>
        </SModal>
    )
}
export default ModalEditGroup
