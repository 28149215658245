import { checkErrorInputFile } from '@/components/shared/AvatarUpload/AvatarUploadContainer'
import SModal from '@/components/shared/Modal/SModal'
import { ToastError } from '@/components/shared/toast/Toast'
import { handleUploadImage } from '@/shared/utils/ultils'
import { cisCameraPlus } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import {
  CAvatar,
  CForm,
  CFormInput,
  CFormLabel,
  CModalBody,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useState } from 'react'
import AddMemberGroup from './AddMemberGroup'
import { socket } from '@/shared/config/socket'
import { useDispatch, useSelector } from 'react-redux'
import { userSelectors } from '../../UserManagement/usersManagement.reducer'
import { RootState } from '@/reducers'
import { ICreateChatResponse } from '@/shared/model/chat.model'
import { AppDispatch } from '@/store'
import { hiddenCreateGroup } from '../../auth/auth.reducer'
import { useRouter } from '@/shared/utils/hooks/useRouter'

interface Iprops {
  setRenderAction: any
}
const ModalAddgroup = ({ setRenderAction }: Iprops) => {
  const dispatch = useDispatch<AppDispatch>()
  const [step, setStep] = useState(0)
  const { user, createGroup } = useSelector(
    (state: RootState) => state.authentication,
  )
  const { navigate, params } = useRouter()

  const handleRemappingImageValue = async (value: any) => {
    if (value.fileAvatar) {
      const avatarUrlPromises = await handleUploadImage(value.fileAvatar)
      value.avatar = avatarUrlPromises
    }
    return value
  }

  const initialValues: any = {
    fileAvatar: null,
    avatar: '',
    name: null,
    users: [],
  }

  const handleFileUploadavatar = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const inputFiles = e.target.files
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return
      const reader = new FileReader()
      reader.onload = (e) => {
        setFieldValue('fileAvatar', inputFiles[0])
        setFieldValue('avatar', e.target?.result)
      }
      reader.readAsDataURL(inputFiles[0])
    }
  }

  const userItems = useSelector(userSelectors.selectAll)
  const handCheckStep = (name: string) => {
    if(!name) {
      return
    }
    setStep(1)
  }

  return (
    <SModal
      visible={createGroup}
      onClose={() => dispatch(hiddenCreateGroup())}
      alignment="center"
    >
      <CModalBody>
        <div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={async (value, { resetForm }) => {
              const values = await handleRemappingImageValue(value)

              const joinUserIds = [...value.users, Number(user?.id)]
              const roomData = {
                users: joinUserIds,
                name: value.name,
                avatar: values.avatar,
                isGroup: 1,
              }

              socket.emit('new_room', roomData)

              socket.on(
                `user_${user?.id}_new_room`,
                (res: ICreateChatResponse) => {
                  if (res?.room?.id) {
                    resetForm()
                    navigate(`/${res?.room?.id}`)
                    setRenderAction(0)
                    setStep(0)
                    dispatch(hiddenCreateGroup())
                  } else {
                    ToastError('Không thể tạo nhóm')
                  }
                },
              )
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              resetForm,
              handleBlur,
            }) => (
              <CForm onSubmit={handleSubmit} className="form-add-group py-2">
                <div className={'gap-4' + (step === 0 ? ' d-flex' : ' d-none')}>
                  <div className="p-0 box-avatar">
                    <div className="d-flex justify-content-center position-relative">
                      <div className="profileImageContainer">
                        <div className="position-relative box-select-file">
                          {values.avatar ? (
                            <CAvatar
                              color="secondary"
                              className="avatar-xxl object-fit-cover avatar-54"
                              src={values.avatar}
                              style={{ width: '100%', height: '100%' }}
                            />
                          ) : (
                            <div
                              className="d-flex flex-column justify-content-center align-items-center"
                              style={{ width: '100%', height: '100%' }}
                            >
                              <CIcon
                                icon={cisCameraPlus}
                                className="mb-3"
                                style={{
                                  width: '33px',
                                  height: '33px',
                                  color: '#fff',
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <CFormLabel
                          htmlFor="custom-task-file-input-1"
                          className={`p-image m-0`}
                        >
                          {values.avatar ? (
                            <CIcon
                              icon={cisCameraPlus}
                              className="upload-button"
                              style={{ color: '#fff' }}
                            />
                          ) : (
                            <div className="add-image"></div>
                          )}
                          <input
                            id="custom-task-file-input-1"
                            className="file-upload"
                            type="file"
                            onClick={(e) => (e.currentTarget.value = '')}
                            onChange={(e) =>
                              handleFileUploadavatar(e, setFieldValue)
                            }
                            accept="image/*"
                          />
                        </CFormLabel>
                      </div>
                    </div>
                  </div>

                  <div className="w-100">
                    <CFormLabel
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgb(64, 167, 228)',
                      }}
                    >
                      Group name
                    </CFormLabel>
                    <CFormInput className='border-0 border-bottom border-2 rounded-0 px-0 text-white' style={{ boxShadow: "none", background: "transparent" }}
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className={step === 1 ? ' d-block' : ' d-none'}>
                  <AddMemberGroup
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>

                <div className='mt-4'>
                  {step === 0 ? (
                    <div className="d-flex justify-content-end gap-4">
                      <div
                        className="cursor-pointer"
                        style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                        onClick={() => dispatch(hiddenCreateGroup())}
                      >
                        Cancel
                      </div>
                      <div
                        className="cursor-pointer"
                        style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                        onClick={() => handCheckStep(values.name)}
                      >
                        Next
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end gap-4">
                      <div
                        className="cursor-pointer"
                        style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                        onClick={() => setStep(0)}
                      >
                        Cancel
                      </div>
                      <div
                        className="cursor-pointer"
                        style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                      >
                        <button
                          type="submit"
                          className="border-0 bg-transparent "
                          style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </CForm>
            )}
          </Formik>
        </div>
      </CModalBody>
    </SModal>
  )
}
export default ModalAddgroup
