import { CImage } from '@coreui/react-pro'
import axios from '@/shared/config/axios-interceptor'
import { useEffect, useState } from 'react'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import 'react-photo-view/dist/react-photo-view.css';
import avatar1 from '@/assets/img/images.png'
import TranslateComponentHTML from '@/shared/utils/googleTranslateHtml'
import { BsFileEarmarkArrowDownFill } from 'react-icons/bs';
import dayjs from 'dayjs';

interface IProps {
    chatRoom: any
}

const FileComponet = ({ chatRoom }: IProps) => {
    const [info, setInfo] = useState([])
    const { params } = useRouter()

    const fetchPhoto = async () => {
        try {
            const res = await axios.get(
                `/message/room/${params?.roomId}?limit=50&sortBy=timestamp&sortOrder=DESC&roomId=${params?.roomId}&type=FILE`,
            )
            setInfo(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPhoto()
    }, [params?.roomId])

    return (
        <>
            <div className="px-4">

                {
                    info.map((item: any, index: number) => {
                        if (item?.content) {
                            const urlObject = new URL(item?.content);
                            const pathname = urlObject.pathname;
                            const basename = pathname.substring(pathname.lastIndexOf('/') + 1);

                            return <a key={index} className='d-flex gap-2 text-decoration-none mb-3' target='_blank' href={item?.content}>
                                <div className='d-flex align-items-center justify-content-center rounded-1' style={{ width: 50, height: 50, background: "#5FBE67" }}>
                                    <BsFileEarmarkArrowDownFill color='#fff' />
                                </div>

                                <div className='d-flex flex-column justify-content-between' >
                                    <div style={{ color: "#fff", fontSize: 14, fontWeight: 600 }}>{basename}</div>
                                    <div style={{ color: "#fff", fontSize: 12, opacity: 0.5 }}>{dayjs(item?.timestamp).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                </div>
                            </a>
                        }
                    })
                }

            </div>
        </>
    )
}

export default FileComponet
