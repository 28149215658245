import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { ResponseStatus } from '../../../shared/enumeration/ResponseStatus';
import { INewUser, IUser } from '../../../shared/model/user.model';
import { IParams } from '../../../shared/shared-interfaces';
import { SystemRole } from '@/shared/enumeration/role';

const prefix = 'user';
// const history = 'revision';

export interface IUserParams extends IParams {
  status?: ResponseStatus;
  projectId?: number; // Lấy tất cả user không phải là nhân viên của dự án được truyền vào
  keyword?: string; // Lấy tất cả user không phải là nhân viên của dự án được truyền vào
  role?: SystemRole;
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IUserParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IUser[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntitiesAgent = createAsyncThunk(`get-all-agent-${prefix}`, async (fields: IUserParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IUser[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IUser>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewUser, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IUser, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IUser>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IUserDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IUserDel, thunkAPI) => {
    try {
      await axios.post<IUser>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
