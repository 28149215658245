import { IUserParams, getEntities as getUserEntities, } from "@/components/modules/UserManagement/usersManagement.api";
import { userSelectors } from "@/components/modules/UserManagement/usersManagement.reducer";
import SModal from "@/components/shared/Modal/SModal";
import { RootState } from "@/reducers";
import { ResponseStatus } from "@/shared/enumeration/ResponseStatus";
import { AppDispatch } from "@/store";
import { CCol, CForm, CHeader, CModalBody, CModalHeader } from "@coreui/react-pro";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { returnUserMultipleItem } from "../../CreateChat";
import { ISelectValue } from "@/shared/shared-interfaces";
import Select, { MultiValue } from 'react-select';
import { IUser } from "@/shared/model/user.model";
import axios from '@/shared/config/axios-interceptor'
import { ToastError } from "@/components/shared/toast/Toast";
import { getEntities, getListContact } from "../../chat.api";
import { IChat } from "@/shared/model/chat.model";


interface Iprops {
    setAddUser: any,
    addUser: any,
    room: any
}

const ModalAddUserToGroup = ({ addUser, setAddUser, room }: Iprops) => {

    
    const getUserId = room?.users.map((i: IUser) => i.id);

    const dispatch = useDispatch<AppDispatch>();
    const { user } = useSelector((state: RootState) => state.authentication);
    const { initialState } = useSelector((state: RootState) => state.chatReducerFe);
    const { listContact } = initialState;

    const [userFilter, setUserFilter] = useState<IUserParams>({
        page: 1,
        limit: 20,
        status: ResponseStatus.ACTIVE,
    });

    const userItems = useSelector(userSelectors.selectAll);

    const dataUserContact = listContact.map((item: IChat) => {
        if (item.isGroup === 0) {
          const users = item.users.filter((i) => i.id !== user?.id)[0]
          return {
            id: users?.id,
            username: users?.username,
            avatar: users?.avatar,
          }
        }
        return null;
      })

    const customUserItems: ISelectValue<string>[] = returnUserMultipleItem(
        dataUserContact.filter((item) => item !== null && !getUserId.includes(item.id)) as IUser[],
        []
    );

    const returnMutiOption = (optionValue: number[], array: ISelectValue<string>[]): ISelectValue<string>[] => {
        if (!optionValue) return [];
        const selectValue = array.filter((item) => optionValue.includes(Number(item.value)));
        return selectValue;
    };

    useEffect(() => {
        dispatch(getListContact(userFilter))
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])


    const initialValues: any = {
        users: [],
    }

    return <SModal visible={addUser} onClose={() => setAddUser(false)}>
        <CModalHeader>Thêm thành viên</CModalHeader>
        <CModalBody>

            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (value, { resetForm }) => {
                    if(value.users.length){
                        try {
                            const api = await axios.post('/room/add-member', {
                                userIds: value.users,
                                roomId: room?.id
                            })
                            if(api){
                                dispatch(getEntities({page: 1, limit: 20, sortOrder: 'DESC', sortBy: 'lastModifiedDate' }))
                            }
                            resetForm()
                        } catch (error) {
                            ToastError('Thêm thành viên thất bại')
                        }
                    }
                    setAddUser(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                    resetForm,
                    handleBlur,
                }) => (
                    <CForm onSubmit={handleSubmit} className="form-add-group py-2">

                        <CCol xs={12} className="mb-24">
                            <Select
                                // defaultValue={null}
                                className="custom-select multi-select height-44"
                                classNamePrefix="react-select"
                                value={returnMutiOption(values.users || [], customUserItems)}
                                onChange={async (newValue: MultiValue<ISelectValue<string> | null>) => {
                                    if (newValue.length <= 10) {
                                        if (newValue.length === 0) {
                                            setUserFilter({
                                                page: 1,
                                                limit: 20,
                                                status: ResponseStatus.ACTIVE,
                                            });
                                        }
                                        const optionValue = newValue.map((item) => Number(item?.value));
                                        setFieldValue(`users`, optionValue);
                                    }
                                }}
                                onMenuScrollToBottom={() => {
                                    setUserFilter({ ...userFilter, limit: userFilter.limit + 20 });
                                }}
                                onInputChange={(newValue: string) => {
                                    setUserFilter({ ...userFilter, key: newValue });
                                }}
                                id={'users'}
                                noOptionsMessage={() => <>No member</>}
                                options={customUserItems}
                                isClearable={true}
                                isMulti
                                closeMenuOnSelect={false}
                                menuPosition={'absolute'}
                                placeholder={"Search"}
                            />
                        </CCol>

                        <div className="d-flex justify-content-end gap-4 mt-5">
                            <div
                                className="cursor-pointer"
                                style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                                onClick={() => setAddUser(false)}
                            >
                                Cancel
                            </div>
                            <div
                                className="cursor-pointer"
                                style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                            >
                                <button
                                    type="submit"
                                    className="border-0 bg-transparent "
                                    style={{ color: 'rgb(64, 167, 228)', fontSize: 16 }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>

                    </CForm>
                )}
            </Formik>

        </CModalBody>
    </SModal>
}

export default ModalAddUserToGroup;