import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import Message from './Message';
import Home from './Home';
import ChatFe from './chat/Chat';
import InviteGroup from './InviteGroup';

const WebManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: <ChatFe />,
  },

  {
    path: '/:roomId',
    element: <ChatFe />,
  },

  {
    path: '/message',
    element: <Message />,
  },

  {
    path: '/invite-group/:roomId',
    element: <InviteGroup  />,
  },

  { path: '*', element: <Navigate to="/404" /> },
];

const WebsManagementRoutes = () => useRoutes(WebManagementLayout);
export default WebsManagementRoutes;
