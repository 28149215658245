import {
  AiOutlineEdit,
  AiOutlineFile,
  AiOutlineInfoCircle,
  AiOutlineLink,
  AiOutlinePicture,
  AiOutlineUserAdd,
} from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { handleChatRoomUserName } from '../chat/Chat'

interface Iprops {
  chatRoom: any
}
const UserInfo = ({ chatRoom }: Iprops) => {
  const { user, showInfo } = useSelector(
    (state: RootState) => state.authentication,
  )
  return (
    <>
      <div style={{ background: '#3C3C3C' }} className="py-1 mt-3"></div>

      <div>
        <div className="d-flex align-items-center py-3">
          <div style={{ width: 90 }} className="d-flex justify-content-center">
            <AiOutlineInfoCircle color="#707579" size={30} />
          </div>
          <div>
            <div className="mb-1" style={{ color: 'rgb(64, 167, 228)' }}>
              @{handleChatRoomUserName(chatRoom, user)}
            </div>
            <div style={{ opacity: 0.5 }}>Username</div>
          </div>
        </div>

        <div style={{ background: '#3C3C3C' }} className="py-1"></div>

        <div className="list-info">
          <div className="item-list d-flex align-items-center mt-4">
            <div
              style={{ width: 90 }}
              className="d-flex justify-content-center"
            >
              <AiOutlinePicture color="#707579" size={30} />
            </div>
            <div>
              <div>8 Photo</div>
            </div>
          </div>

          <div className="item-list d-flex align-items-center mt-2">
            <div
              style={{ width: 90 }}
              className="d-flex justify-content-center"
            >
              <AiOutlineFile color="#707579" size={30} />
            </div>
            <div>
              <div>10 File</div>
            </div>
          </div>

          <div className="item-list d-flex align-items-center mt-2">
            <div
              style={{ width: 90 }}
              className="d-flex justify-content-center"
            >
              <AiOutlineLink color="#707579" size={30} />
            </div>
            <div>
              <div>1 Sharelink</div>
            </div>
          </div>

          <div style={{ background: '#F1F1F1' }} className="py-1 my-3"></div>


          <div className="item-list d-flex align-items-center mt-2">
            <div
              style={{ width: 90 }}
              className="d-flex justify-content-center"
            >
              <AiOutlineEdit color="#707579" size={30} />
            </div>
            <div>
              <div>Edit contact</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserInfo
