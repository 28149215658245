import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createEntity,
  getEntities,
  getEntity,
  updateEntity,
} from '../settingsManagement.api'
import { CButton, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react-pro'
import CustomFormikEditor from '@/components/shared/CustomEditor/CustomEditor'
import { Formik } from 'formik'
import { ToastSuccess } from '@/components/shared/toast/Toast'
import { fetching, resetEntity, selectEntityById } from '../settingsManagement.reducer'
import { INewSettings, ISettings } from '@/shared/model/settings.model'

const SettingManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate, params } = useRouter()

  const { initialState } = useSelector(
    (state: RootState) => state.settingsReducer,
  )
  const {
    filterState,
    totalItems,
    totalPages,
    loading,
    updateEntitySuccess,
    deleteEntitySuccess,
  } = initialState

  useEffect(() => {
    if (updateEntitySuccess) {
      ToastSuccess('Cập nhật thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    dispatch(getEntities(filterState))
  }, [dispatch, filterState])

  const initialValues: INewSettings = {
    name: '1',
    content1: '',
    content2: '',
    content3: '',
    content4: '',
    content5: '',
    content6: '',
  }

  const config = useSelector(selectEntityById("1"))

  if(!config) return <></>

  return (
    <>
      <div className="tab-outlet-content">
        <Formik
          initialValues={config || initialValues}
          // validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(value) => {
            dispatch(fetching())
            if (config) {
              dispatch(updateEntity(value as ISettings))
            } else {
              value.name = '1'
              dispatch(createEntity(value))
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <CForm onSubmit={handleSubmit} className="custom-form">
              <CRow>
                <CCol xs={12} className='mb-3'>
                  <CRow>
                    <CCol xs="12" md={3} lg={2}>
                      <CFormLabel htmlFor="content1" style={{ fontSize: 16 }}>
                        Phí giao dịch
                      </CFormLabel>
                    </CCol>

                    <CCol xs="12" md={9} lg={10}>
                      <CFormInput name="content1"
                        value={values.content1}
                        onChange={handleChange} />
                    </CCol>
                  </CRow>
                </CCol>


                <CCol xs={12} className='mb-3'>
                  <CRow>
                    <CCol xs="12" md={3} lg={2}>
                      <CFormLabel htmlFor="content2" style={{ fontSize: 16 }}>
                        Thời gian giải quyết
                      </CFormLabel>
                    </CCol>

                    <CCol xs="12" md={9} lg={10}>
                      <CFormInput name="content2"
                        value={values.content2}
                        onChange={handleChange} />
                    </CCol>
                  </CRow>
                </CCol>



                <CCol xs={12} className='mb-3'>
                  <CRow>
                    <CCol xs="12" md={3} lg={2}>
                      <CFormLabel htmlFor="content3" style={{ fontSize: 16 }}>
                        Tỷ lệ lại hỗn hợp
                      </CFormLabel>
                    </CCol>

                    <CCol xs="12" md={9} lg={10}>
                      <CFormInput name="content3"
                        value={values.content3}
                        onChange={handleChange} />
                    </CCol>
                  </CRow>
                </CCol>


                <CCol xs="12">
                  <CButton type="submit" className="btn btn-primary">
                    Lưu cấu hình
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          )}
        </Formik>
      </div>
    </>
  )
}

export default SettingManagement