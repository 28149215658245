import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import UserOtpManagement from './UserOtpManagement';


const UserOtpManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: (
        <UserOtpManagement />
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const UsersOtpManagementRoutes = () => useRoutes(UserOtpManagementLayout);
export default UsersOtpManagementRoutes;
