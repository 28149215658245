import SModal from '@/components/shared/Modal/SModal'
import { RootState } from '@/reducers'
import { socket } from '@/shared/config/socket'
import { ResponseStatus } from '@/shared/enumeration/ResponseStatus'
import { IChat, ICreateChatResponse, INewChat } from '@/shared/model/chat.model'
import { ISelectValue } from '@/shared/shared-interfaces'
import { AppDispatch } from '@/store'
import {
  CCol,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select, { MultiValue } from 'react-select'
import * as Yup from 'yup'
import {
  getEntities as getUserEntities,
  IUserParams,
} from '../../UserManagement/usersManagement.api'
import { IUser } from '@/shared/model/user.model'
import { unionWith } from 'lodash'
import { userSelectors } from '../../UserManagement/usersManagement.reducer'
import { getListContact } from '../chat/chat.api'

export const returnUserMultipleItem = (
  userItem: IUser[],
  selectUser?: IUser[],
) => {
  if (!userItem) return []
  if (selectUser) {
    const userSelect = selectUser.map((item) => {
      return {
        value: item.id,
        label: item.username,
      }
    })
    const userSelectList = userItem.map((item) => {
      return {
        value: item.id,
        label: `${item.username}`,
      }
    })
    
    const result = unionWith(
      userSelect,
      userSelectList,
      (a, b) => a.value === b.value,
    )

    return result
  }
  return userItem.map((item) => {
    return {
      value: item.id,
      label: item.username,
    }
  })
}

const initialValues: INewChat = {
  users: [],
  content: '',
}

interface ICreateChatProps {
  setFieldValue: any
  values: any
}

const AddMemberGroup = (props: ICreateChatProps) => {
  const { setFieldValue, values } = props
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)
  const { initialState } = useSelector(
    (state: RootState) => state.chatReducerFe,
  )
  const { filterState, totalPages, listContact } = initialState
  const [loading, setLoading] = useState<boolean>(false)
  const [userFilter, setUserFilter] = useState<IUserParams>({
    page: 1,
    limit: 20,
    status: ResponseStatus.ACTIVE,
  })


  const dataUserContact = listContact.map((item: IChat) => {
    if (item.isGroup === 0) {
      const users = item.users.filter((i) => i.id !== user?.id)[0]
      return {
        id: users?.id,
        username: users?.username,
        avatar: users?.avatar,
      }
    }
    return null;
  })

  const customUserItems: ISelectValue<string>[] = returnUserMultipleItem(
    dataUserContact.filter((item) => item !== null) as IUser[],
    [],
  )

  const returnMutiOption = (
    optionValue: number[],
    array: ISelectValue<string>[],
  ): ISelectValue<string>[] => {
    if (!optionValue) return []
    const selectValue = array.filter((item) =>
      optionValue.includes(Number(item.value)),
    )
    return selectValue
  }

  const validationSchema = Yup.object().shape({
    users: Yup.array().min(1, 'Vui lòng chọn thành viên'),
    content: Yup.string().trim().required('Không được để trống'),
  })

  useEffect(() => {
    dispatch(getListContact(userFilter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CRow className="g-4">
      <CCol>
        <h5>Add Member</h5>
      </CCol>
      <CCol xs={12} className="mb-24">
        {/* <CFormLabel className="text-medium-xs text-gray-modern-700 mb-6">Chọn thành viên</CFormLabel> */}
        <Select
          defaultValue={null}
          className="custom-select multi-select height-44"
          classNamePrefix="react-select"
          value={returnMutiOption(values.users || [], customUserItems)}
          onChange={async (
            newValue: MultiValue<ISelectValue<string> | null>,
          ) => {
            if (newValue.length <= 10) {
              if (newValue.length === 0) {
                setUserFilter({
                  page: 1,
                  limit: 20,
                  status: ResponseStatus.ACTIVE,
                })
              }
              const optionValue = newValue.map((item) => Number(item?.value))
              setFieldValue(`users`, optionValue)
            }
          }}
          onMenuScrollToBottom={() => {
            setUserFilter({ ...userFilter, limit: userFilter.limit + 20 })
          }}
          onInputChange={(newValue: string) => {
            setUserFilter({ ...userFilter, key: newValue })
          }}
          id={'users'}
          noOptionsMessage={() => <>No member</>}
          options={customUserItems}
          isClearable={true}
          isMulti
          closeMenuOnSelect={false}
          menuPosition={'absolute'}
          placeholder={'Search'}
        />
      </CCol>
    </CRow>
  )
}

export default AddMemberGroup
