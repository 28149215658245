import { combineReducers } from 'redux';
import container from './components/containers/reducer';
import messageReducer from './components/modules/Chat/Message/message.reducer';
import chatReducer from './components/modules/Chat/chat.reducer';
import chatReducerFe from './components/modules/Web/chat/chat.reducer';

import permissionReducer from './components/modules/SystemSetting/PermissionGroup/permission.reducer';
import usersReducer from './components/modules/UserManagement/usersManagement.reducer';
import authentication from './components/modules/auth/auth.reducer';
import settingsReducer from './components/modules/SettingsManagement/settingsManagement.reducer';


import usersOtpReducer from './components/modules/UserOtpManagement/usersOtpManagement.reducer';

const rootReducer = combineReducers({
  container,
  authentication,
  usersReducer,
  permissionReducer,
  messageReducer,
  chatReducer,
  settingsReducer,
  chatReducerFe,
  usersOtpReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
