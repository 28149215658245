import axios from '@/shared/config/axios-interceptor';
import { IChat, INewChat } from '@/shared/model/chat.model';
import { IParams } from '@/shared/shared-interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';

const prefix = 'room';

export interface IChatParams extends IParams {
  keyword?: string
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IChatParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IChat[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getListContact = createAsyncThunk(`get-list-contact-${prefix}`, async (fields: IChatParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IChat[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IChat>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewChat, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IChat, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IChat>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const updatePassword = createAsyncThunk(`update-password-${prefix}`, async (body: IChat, thunkAPI) => {
  try {
    const { data } = await axios.post<IChat>(`${prefix}/update-password`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});