import { RootState } from '@/reducers'
import { socket } from '@/shared/config/socket'
import { IChat } from '@/shared/model/chat.model'
import { IUser } from '@/shared/model/user.model'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import {
  CAvatar,
  CCol,
  CRow,
} from '@coreui/react-pro'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEntities } from './chat.api'
import {
  fetching,
  initialChatFilter,
  setFilterState,
} from './chat.reducer'
import CreateChat from './CreateChat'
import Message from './Message/Message'
import { toggleMessageInfo } from './Message/message.reducer'
import avatar1 from '@/assets/img/images.png'
import RenderAction from '../RenderAction'
import ModalAddgroup from '../components/ModalAddGroup'
import ModalAddContact from '../components/ModalAddContact'
import TranslateComponent from '@/shared/utils/googleTranslate'
import noti from '@/assets/noti.mp3'
import { IMessage } from '@/shared/model/message.model'


export const handleChatRoomName = (
  room: IChat,
  user: IUser | null,
  maxLength = 30,
) => {
  if (!user) return ''
  if (room.isGroup === 1) {
    return room.name
  } else {
    const joinUsers = room.users.filter((item) => item.id !== user.id)
    const roomName = joinUsers
      .map((user) => user.fullName || user.username)
      .join(', ')
    return roomName;
  }
}

export const handleChatcheckIsOnline = (
  room: IChat,
  user: IUser | null,
  maxLength = 30,
) => {
  if (!user) return ''
  if (room.isGroup === 1) {
    return room.name
  } else {
    const joinUsers = room.users.filter((item) => item.id !== user.id)
    const user1 = joinUsers[0];
    return user1.chatStatus == 1 ? <TranslateComponent stringText="Online" /> : <TranslateComponent stringText={dayjs(user1.dateOff)
      .fromNow()
      .replaceAll('một', '1')} />
  }

}

export const handleChatRoomUserName = (
  room: IChat,
  user: IUser | null,
  maxLength = 30,
) => {
  if (!user) return ''
  if (room.isGroup === 1) {
    return room.name
  } else {
    const joinUsers = room.users.filter((item) => item.id !== user.id)
    const roomName = joinUsers
      .map((user) => user.username)
      .join(', ')
    return roomName;
  }
}

export const handleChatAvatar = (
  room: IChat,
  user: IUser | null,
  maxLength = 30,
) => {
  if (!user) return ''
  if (room.isGroup === 1) {
    return <CAvatar src={room.avatar || avatar1} className="avatar-54" />
  } else {
    const joinUsers = room.users.filter((item) => item.id !== user.id)
    return <CAvatar src={joinUsers[0].avatar || avatar1} className="avatar-54" />;
  }
}

const ChatFe = () => {
  const { navigate, params } = useRouter()
  const { roomId } = params
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)
  const { initialState } = useSelector((state: RootState) => state.chatReducer)
  const { filterState, totalPages } = initialState
  const [createChatVisible, setCreateChatVisible] = useState<boolean>(false)
  const [renderAction, setRenderAction] = useState<number>(0)
  const [searchMsgKey, setSearchMsgKey] = useState<string>('')
  const audioRef: any = useRef(null)


  const listChatRef = useRef<HTMLDivElement>(null)

  const handleCreateChat = () => {
    dispatch(toggleMessageInfo(false))
    setCreateChatVisible(true)
  }

  useEffect(() => {
    const fetchData = (newMsg?: IMessage) => {
      dispatch(fetching())
      dispatch(getEntities(filterState))
      if(newMsg && (Number(newMsg?.senderId) !== Number(user?.id)) && user?.isNotify == 'YES'){
        audioRef.current.play().catch((error: any) => {
          console.log('Audio play failed:', error)
        })
      }
    }

    fetchData()

    socket.on(`user_${user?.id}_new_message`, fetchData)

    return () => {
      socket.off(`user_${user?.id}_new_message`, fetchData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), roomId, user])

  useEffect(() => {
    if (searchMsgKey.trim()) {
      const refetchTimer = setTimeout(() => {
        dispatch(setFilterState({ ...filterState, key: searchMsgKey.trim() }))
      }, 1000)
      return () => clearTimeout(refetchTimer)
    } else {
      dispatch(setFilterState(initialChatFilter))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMsgKey])

  useEffect(() => {
    const handleScroll = () => {
      const div = listChatRef.current
      if (!div) return
      if (div.scrollTop + div.clientHeight === div.scrollHeight) {
        if (totalPages > 1)
          dispatch(
            setFilterState({ ...filterState, limit: filterState.limit + 20 }),
          )
      }
    }

    const div = listChatRef.current
    if (!div) return
    div.addEventListener('scroll', handleScroll)

    return () => {
      div.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), totalPages, roomId])

  useEffect(() => {
    return () => {
      setRenderAction(0)
      setSearchMsgKey('')
      dispatch(setFilterState(initialChatFilter))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  // const getRoom = async (userId: number) => {
  //   try {
  //     const response = await axios.get('/room/get-room-user-custom/' + userId)
  //     if (!response?.data?.id) {
  //       socket.emit('new_room', { users: [user?.id, userId], name: 'Bắt đầu' })

  //       socket.on(`user_${user?.id}_new_room`, (res: any) => {
  //         navigate(`/${res.room?.id}`)
  //         const messageData = {
  //           id: res.room?.id,
  //           content: 'Xin chào!',
  //         }

  //         socket.emit('send_message', messageData)
  //         socket.off(`user_${user?.id}_new_room`)
  //       })
  //     } else {
  //       navigate(`/${response?.data?.id}`)
  //     }
  //   } catch (error) {
  //     console.error('Error:', error)
  //   }
  // }

  // const checkSendMessenge = (userId: string) => {
  //   getRoom(+userId)

  //   // get-room-user-custom/:id
  //   // return userItems.filter((i) => i.id === item.fromUser.id).length > 0
  // }

  return (
    <div
      className="position-fixed"
      style={{ width: '100vw', height: '100vh' }}
    >
      <audio ref={audioRef} src={noti} />
      <CRow className="m-0 ">
        <CCol
          xs={12}
          md={3}
          className={roomId ? 'list-chat-container custom-form hiden-mobile overflow-auto' : 'list-chat-container custom-form overflow-auto'}
          style={{ height: '100vh', background: "#1F2020" }}
        >
          <RenderAction
            setSearchMsgKey={setSearchMsgKey}
            searchMsgKey={searchMsgKey}
            renderAction={renderAction}
            setRenderAction={setRenderAction}
            listChatRef={listChatRef}
          />
        </CCol>
        <CCol xs={12} md={9} className="p-0">
          <Message />
        </CCol>
      </CRow>

      {createChatVisible ? (
        <CreateChat
          visible={createChatVisible}
          setVisible={setCreateChatVisible}
        />
      ) : null}

      <ModalAddgroup setRenderAction={setRenderAction} />
      <ModalAddContact setRenderAction={setRenderAction} />

    </div>
  )
}

export default ChatFe
