import SModal from '@/components/shared/Modal/SModal';
import { RootState } from '@/reducers';
import { socket } from '@/shared/config/socket';
import { ResponseStatus } from '@/shared/enumeration/ResponseStatus';
import { ICreateChatResponse, INewChat } from '@/shared/model/chat.model';
import { ISelectValue } from '@/shared/shared-interfaces';
import { AppDispatch } from '@/store';
import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow
} from '@coreui/react-pro';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { MultiValue } from 'react-select';
import * as Yup from 'yup';
// import { getEntities as getUserEntities, IUserParams } from '../UserManagement/usersManagement.api';
// import { userSelectors } from '..//UserManagement/usersManagement.reducer';
import { IUser } from '@/shared/model/user.model';
import { unionWith } from 'lodash';


export const returnUserMultipleItem = (userItem: IUser[], selectUser?: IUser[]) => {
  if (!userItem) return [];
  if (selectUser) {
    const userSelect = selectUser.map((item) => {
      return {
        value: item.id,
        label: item.username,
      };
    });
    const userSelectList = userItem.map((item) => {
      return {
        value: item.id,
        label: `${item.username}`,
      };
    });
    // const result = [...userSelect, ...userSelectList];
    const result = unionWith(userSelect, userSelectList, (a, b) => a.value === b.value);

    return result;
  }
  return userItem.map((item) => {
    return {
      value: item.id,
      label: item.username,
    };
  });
};

const initialValues: INewChat = {
  users: [],
  content: '',
};

interface ICreateChatProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const CreateChat = (props: ICreateChatProps) => {
  const { visible, setVisible } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.authentication);

  const [loading, setLoading] = useState<boolean>(false);
  // const [userFilter, setUserFilter] = useState<IUserParams>({
  //   page: 1,
  //   limit: 20,
  //   status: ResponseStatus.ACTIVE,
  // });

  // const userItems = useSelector(userSelectors.selectAll);

  // const customUserItems: ISelectValue<string>[] = returnUserMultipleItem(
  //   userItems.filter((item) => item.id !== user?.id),
  //   []
  // );

  // const customUserItems: ISelectValue<string>[] = []

  const returnMutiOption = (optionValue: number[], array: ISelectValue<string>[]): ISelectValue<string>[] => {
    if (!optionValue) return [];
    const selectValue = array.filter((item) => optionValue.includes(Number(item.value)));
    return selectValue;
  };

  const validationSchema = Yup.object().shape({
    users: Yup.array().min(1, 'Vui lòng chọn thành viên'),
    content: Yup.string().trim().required('Không được để trống'),
  });


  const handleOnClose = () => {
    setVisible(false);
  };

  // useEffect(() => {
  //   dispatch(getUserEntities(userFilter));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [JSON.stringify(userFilter)]);

  return (
    <SModal visible={visible} onClose={handleOnClose} backdrop="static" className="custom-modal">
      {/* <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        // validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(value, { resetForm }) => {
          setLoading(true);

          const joinUserIds = [...value.users, Number(user?.id)];
          const joinUsers = userItems.filter((item) => joinUserIds.some((id) => Number(item.id) === id));
          const roomData = {
            users: joinUserIds,
            name: joinUsers.map((user) => user.fullName || user.username).join(', '),
          };

          
          socket.emit('new_room', roomData);

          console.log(roomData);

          socket.on(`user_${user?.id}_new_room`, (res: ICreateChatResponse) => {
            const messageData = {
              id: res.room?.id,
              content: value.content,
            };
            socket.emit('send_message', messageData);
            socket.off(`user_${user?.id}_new_room`);
            setLoading(false);
            setVisible(false);
            resetForm();
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <CModalHeader>{'Tạo tin nhắn mới'}</CModalHeader>
            <CModalBody>
              <CRow>
                <CCol xs={12} className="mb-24">
                  <CFormLabel className="text-medium-xs text-gray-modern-700 mb-6">Gửi tới</CFormLabel>
                  <Select
                    defaultValue={null}
                    className="custom-select multi-select height-44"
                    classNamePrefix="react-select"
                    value={returnMutiOption(values.users || [], customUserItems)}
                    onChange={async (newValue: MultiValue<ISelectValue<string> | null>) => {
                      if(newValue.length <= 1) {
                        if (newValue.length === 0) {
                          setUserFilter({
                            page: 1,
                            limit: 20,
                            status: ResponseStatus.ACTIVE,
                          });
                        }
                        const optionValue = newValue.map((item) => Number(item?.value));
                        setFieldValue(`users`, optionValue);
                      }
                    }}
                    onMenuScrollToBottom={() => {
                      setUserFilter({ ...userFilter, limit: userFilter.limit + 20 });
                    }}
                    onInputChange={(newValue: string) => {
                      setUserFilter({ ...userFilter, key: newValue });
                    }}
                    id={'users'}
                    noOptionsMessage={() => <>Không có thành viên nào</>}
                    options={customUserItems}
                    isClearable={true}
                    isMulti
                    closeMenuOnSelect={false}
                    menuPosition={'absolute'}
                    placeholder={"Gửi tới..."}
                  />
                  <CFormFeedback invalid className={!!errors.users && touched.users ? 'd-block' : 'd-none'}>
                    {errors.users}
                  </CFormFeedback>
                </CCol>

                <CCol xs={12} className="mb-24">
                  <CFormLabel className="text-medium-xs mb-6 label-gray-700">Tin nhắn</CFormLabel>
                  <CFormInput
                    className="form-height-44"
                    name="content"
                    autoComplete="off"
                    value={values.content}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={!!errors.content && touched.content}
                    placeholder={'Viết tin nhắn...'}
                  />
                  <CFormFeedback invalid className={!!errors.content && touched.content ? 'd-block' : 'd-none'}>
                    {errors.content}
                  </CFormFeedback>
                </CCol>
              </CRow>
            </CModalBody>

            <CModalFooter className="d-flex justify-content-end">
              <CButton
                className="btn-custom minw-120 variant-gray-300"
                onClick={() => {
                  resetForm();
                  setVisible(false);
                }}
              >
                Huỷ
              </CButton>
              <CButton className="btn-custom minw-120 primary-500" type="submit" disabled={loading}>
                Tạo
              </CButton>
            </CModalFooter>
          </CForm>
        )}
      </Formik> */}
    </SModal>
  );
};

export default CreateChat;