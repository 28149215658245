export enum Permission {
  PERMISSION_GROUP_CREATE = 'PERMISSION_GROUP_CREATE', // Tạo nhóm quyền
  PERMISSION_GROUP_LIST = 'PERMISSION_GROUP_LIST', // Tất cả nhóm quyền
  PERMISSION_GROUP_DETAIL = 'PERMISSION_GROUP_DETAIL', // Chi tiết nhóm quyền
  PERMISSION_GROUP_UPDATE = 'PERMISSION_GROUP_UPDATE', // Cập nhật nhóm quyền
  PERMISSION_GROUP_DELETE = 'PERMISSION_GROUP_DELETE', // Xóa nhóm quyền

  USER_CREATE = 'USER_CREATE', // Tạo tài khoản
  USER_LIST = 'USER_LIST', // Tất cả tài khoản
  USER_DETAIL = 'USER_DETAIL', // Chi tiết tài khoản

  PROJECT_LIST = 'PROJECT_LIST', // Danh sách dự án
  PROJECT_CREATE = 'PROJECT_CREATE', // Tạo dự án
  PROJECT_DETAIL = 'PROJECT_DETAIL', // Chi tiết dự án
  PROJECT_UPDATE = 'PROJECT_UPDATE', // Cập nhật dự án
  PROJECT_DELETE = 'PROJECT_DELETE', // Xóa dự án
}

export const permissionsArray: Permission[] = [
  Permission.PERMISSION_GROUP_CREATE,
  Permission.PERMISSION_GROUP_LIST,
  Permission.PERMISSION_GROUP_DETAIL,
  Permission.PERMISSION_GROUP_UPDATE,
  Permission.PERMISSION_GROUP_DELETE,
  Permission.USER_CREATE,
  Permission.USER_LIST,
  Permission.USER_DETAIL,
  Permission.PROJECT_LIST,
  Permission.PROJECT_CREATE,
  Permission.PROJECT_DETAIL,
  Permission.PROJECT_UPDATE,
  Permission.PROJECT_DELETE,
];

export const mapPermissionsToName: { [key in Permission]: string } = {
  [Permission.PERMISSION_GROUP_CREATE]: 'Tạo mới quyền',
  [Permission.PERMISSION_GROUP_LIST]: 'Xem Danh sách quyền',
  [Permission.PERMISSION_GROUP_DETAIL]: 'Xem Chi tiết quyền',
  [Permission.PERMISSION_GROUP_UPDATE]: 'Cập nhật quyền',
  [Permission.PERMISSION_GROUP_DELETE]: 'Xóa quyền',

  [Permission.USER_CREATE]: 'Tạo tài khoản',
  [Permission.USER_LIST]: 'Xem Danh sách tài khoản',
  [Permission.USER_DETAIL]: 'Xem Chi tiết tài khoản',

  [Permission.PROJECT_LIST]: 'Xem Danh sách dự án',
  [Permission.PROJECT_CREATE]: 'Tạo dự án',
  [Permission.PROJECT_DETAIL]: 'Xem Chi tiết dự án',
  [Permission.PROJECT_UPDATE]: 'Cập nhật dự án',
  [Permission.PROJECT_DELETE]: 'Xóa dự án',
};



export const permissionGroupArray: Permission[] = [
  Permission.PERMISSION_GROUP_CREATE,
  Permission.PERMISSION_GROUP_LIST,
  Permission.PERMISSION_GROUP_DETAIL,
  Permission.PERMISSION_GROUP_UPDATE,
  Permission.PERMISSION_GROUP_DELETE,
];

export const userManagementPermissionArray: Permission[] = [
  Permission.USER_CREATE,
  Permission.USER_LIST,
  Permission.USER_DETAIL,
];

export const projectPermissionArray: Permission[] = [
  Permission.PROJECT_LIST,
  Permission.PROJECT_CREATE,
  Permission.PROJECT_DETAIL,
  Permission.PROJECT_UPDATE,
  Permission.PROJECT_DELETE,
];
