import avatar from '@/assets/img/images.png'
import UserPlus01Icon from '@/components/shared/icons/UserPlus01Icon'
import Users02Icon from '@/components/shared/icons/Users02Icon'
import XIcon from '@/components/shared/icons/XIcon'
import { RootState } from '@/reducers'
import { IChat } from '@/shared/model/chat.model'
import { CAvatar, CButton, CContainer } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { toggleMessageInfo } from './message.reducer'
import TranslateComponent from '@/shared/utils/googleTranslate'
import {
  AiFillLock,
  AiOutlineEdit,
  AiOutlineFile,
  AiOutlineInfoCircle,
  AiOutlineLink,
  AiOutlinePicture,
  AiOutlineUserAdd,
} from 'react-icons/ai'
import {
  handleChatAvatar,
  handleChatRoomName,
  handleChatRoomUserName,
} from '../Chat'
import { useEffect, useState } from 'react'
import { BsArrowLeftShort } from 'react-icons/bs'
import Photo from './components/Photo'
import axios from '@/shared/config/axios-interceptor'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import LinkComponet from './components/Link'
import ModalAddUserToGroup from './components/ModalAddUserToGroup'
import ModalEditGroup from './components/ModalEditGroup'
import FileComponet from './components/File'
import SetPassword from './components/SetPassword'
import { ToastSuccess } from '@/components/shared/toast/Toast'

interface IMessageInfo {
  chatRoom: IChat
}

const MessageInfo = (props: IMessageInfo) => {
  const { chatRoom } = props
  const dispatch = useDispatch()
  const { params, navigate } = useRouter()

  const { initialState } = useSelector(
    (state: RootState) => state.messageReducer,
  )
  const { messageInfoShow } = initialState

  const toggle = () => {
    dispatch(toggleMessageInfo(!messageInfoShow))
  }

  const { user } = useSelector((state: RootState) => state.authentication)

  const [step, setStep] = useState<number>(0)

  const [info, setInfo] = useState({
    imageCount: 0,
    fileCount: 0,
    linkCount: 0,
  })

  const fetch = async () => {
    try {
      const res = await axios.get(`/message/total-info/${params?.roomId}`)
      setInfo(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetch()
  }, [params?.roomId, messageInfoShow])

  const [addUser, setAddUser] = useState<boolean>(false)

  const [editGroup, setEditGroup] = useState<boolean>(false)

  const leaveGroup = async () => {
    try {
      const data = await axios.post(`/room/remove-member`, {
        userId: user?.id,
        roomId: params?.roomId,
      })
      if (data) {
        navigate('/')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteContact = async () => {
    try {
      const data = await axios.delete(`/room/` + params?.roomId)
      if (data) {
        navigate('/')
      }
    } catch (error) {
      console.log(error)
    }
  }


  const copyLinkInvite = async (chatRoom: IChat) => {
    try {
      await navigator.clipboard.writeText(window.location.origin + '/#/invite-group/' + chatRoom.id);
      ToastSuccess('Copy link thành công!');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <CContainer
      className={`h-100 message-info-container message-info-container-fe overflow-auto ${
        messageInfoShow ? 'fixed' : ''
      }`}
    >
      <div
        className="chat-info-header flex-wrap border-0 mb-3"
        style={{ height: 56 }}
      >
        <div className="d-flex align-items-center gap-2">
          {step !== 0 && (
            <div className="cursor-pointer" onClick={() => setStep(0)}>
              <BsArrowLeftShort color="#fff" size={25} />
            </div>
          )}
          <span className="text-medium-md text-white">
            {step === 0 && <TranslateComponent stringText="Thông tin" />}
            {step === 1 && <TranslateComponent stringText="Hình ảnh" />}
            {step === 2 && <TranslateComponent stringText="File" />}
            {step === 3 && <TranslateComponent stringText="Link" />}
            {step === 4 && <TranslateComponent stringText="Set Password" />}
          </span>
        </div>
        <XIcon onClick={toggle} className="cursor-pointer" color="#fff" />
      </div>

      {step === 0 && (
        <>
          <div>
            <div className="d-flex gap-3 align-items-center px-3">
              <div className="avatar-info">
                {handleChatAvatar(chatRoom, user)}
              </div>

              {chatRoom.isGroup === 1 ? (
                <>
                  <div>
                    <h5
                      onClick={() => setEditGroup(true)}
                      className="m-0 d-flex align-items-start gap-2 cursor-pointer"
                    >
                      {handleChatRoomName(chatRoom, user)}
                      <div className="cursor-pointer mb-1">
                        <AiOutlineEdit />
                      </div>
                    </h5>
                    <span style={{ opacity: 0.7, fontSize: 14 }}>
                      {chatRoom?.users?.length + ' Members'}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <h5 className="m-0 d-flex align-items-start gap-2">
                      {handleChatRoomName(chatRoom, user)}
                    </h5>
                    <span style={{ opacity: 0.7, fontSize: 14 }}>Online</span>
                  </div>
                </>
              )}
            </div>

            <div style={{ background: '#3C3C3C' }} className="py-1 mt-3"></div>

            {chatRoom.isGroup === 1 ? (
              <>
                <div className="d-flex align-items-center py-3">
                  <div
                    style={{ width: 90 }}
                    className="d-flex justify-content-center"
                  >
                    <AiOutlineInfoCircle color="#fff" size={30} />
                  </div>
                  <div>
                    <div
                      className="mb-1"
                      style={{ color: 'rgb(64, 167, 228)' }}
                    >
                      {handleChatRoomName(chatRoom, user)}
                    </div>
                    <div style={{ opacity: 0.5, fontSize: 12 }}>
                      @{handleChatRoomUserName(chatRoom, user)}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center py-3">
                <div
                  style={{ width: 90 }}
                  className="d-flex justify-content-center"
                >
                  <AiOutlineInfoCircle color="#fff" size={30} />
                </div>
                <div>
                  <div className="mb-1" style={{ color: 'rgb(64, 167, 228)' }}>
                    {handleChatRoomName(chatRoom, user)}
                  </div>
                  <div style={{ opacity: 0.5, fontSize: 12 }}>
                    @{handleChatRoomUserName(chatRoom, user)}
                  </div>
                </div>
              </div>
            )}

            <div style={{ background: '#3C3C3C' }} className="py-1"></div>

            <div className="list-info">
              <div
                onClick={() => setStep(1)}
                className="item-list d-flex align-items-center mt-4"
              >
                <div
                  style={{ width: 90 }}
                  className="d-flex justify-content-center"
                >
                  <AiOutlinePicture color="#fff" size={30} />
                </div>
                <div>{info.imageCount} Photo</div>
              </div>

              <div
                onClick={() => setStep(2)}
                className="item-list d-flex align-items-center mt-2"
              >
                <div
                  style={{ width: 90 }}
                  className="d-flex justify-content-center"
                >
                  <AiOutlineFile color="#fff" size={30} />
                </div>
                <div>{info.fileCount} File</div>
              </div>

              <div
                onClick={() => setStep(3)}
                className="item-list d-flex align-items-center mt-2"
              >
                <div
                  style={{ width: 90 }}
                  className="d-flex justify-content-center"
                >
                  <AiOutlineLink color="#fff" size={30} />
                </div>
                <div>{info.linkCount} Sharelink</div>
              </div>

              {chatRoom.isGroup === 1 && chatRoom.createdBy == user?.id && (
                <div
                  onClick={() => setStep(4)}
                  className="item-list d-flex align-items-center mt-2"
                >
                  <div
                    style={{ width: 90 }}
                    className="d-flex justify-content-center"
                  >
                    <AiFillLock color="#fff" size={30} />
                  </div>
                  <div>Set Password</div>
                </div>
              )}

              {chatRoom.isGroup === 0 && (
                <div
                  onClick={() => setStep(4)}
                  className="item-list d-flex align-items-center mt-2"
                >
                  <div
                    style={{ width: 90 }}
                    className="d-flex justify-content-center"
                  >
                    <AiFillLock color="#fff" size={30} />
                  </div>
                  <div>Set Password</div>
                </div>
              )}

              {chatRoom.isGroup === 1 && (
                <div
                  onClick={() => copyLinkInvite(chatRoom)}
                  className="item-list d-flex align-items-center mt-2"
                >
                  <div
                    style={{ width: 90 }}
                    className="d-flex justify-content-center"
                  >
                    <AiOutlineUserAdd color="#fff" size={30} />
                  </div>
                  <div>Copy link to invite</div>
                </div>
              )}

              <div
                style={{ background: '#3C3C3C' }}
                className="py-1 my-3"
              ></div>
            </div>
          </div>

          {chatRoom.isGroup === 1 ? (
            <>
              <div className="message-info-item border-0">
                <div className="d-flex align-items-center text-sm text-white">
                  <Users02Icon color="#fff" className="me-2" />
                  {chatRoom?.users?.length}
                  <p className="m-0 ms-1 text-white">
                    <TranslateComponent stringText="thành viên" />
                  </p>
                </div>
                <div
                  onClick={() => setAddUser(true)}
                  className="add-member-icon"
                >
                  <UserPlus01Icon color="#fff" />
                </div>
              </div>

              <div className="list-chat-member h-auto">
                {chatRoom.users.map((u) => (
                  <div
                    key={`message-member-${u?.id}`}
                    className="message-info-member"
                  >
                    <div className="avatar-40 me-md-12">
                      <CAvatar src={u.avatar || avatar} className="avatar-40" />
                    </div>
                    <div className="w-100 ms-12">
                      <p className="chat-room-name mb-1">
                        {u?.fullName || u?.username}
                      </p>
                      <p className="text-xs text-gray-modern-400 m-0">
                        @{u?.username}{' '}
                        {chatRoom?.createdBy == (Number(u?.id) ?? 0) ? (
                          <TranslateComponent stringText="- Người sử hữu nhóm" />
                        ) : (
                          ''
                        )}{' '}
                        {chatRoom?.createdBy == (Number(user?.id) ?? 0) &&
                        chatRoom?.createdBy == (Number(u?.id) ?? 0) ? (
                          <TranslateComponent stringText="(Tôi)" />
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}

          {chatRoom.isGroup === 1 && chatRoom.createdBy == user?.id ? (
            <>
              <div className="p-3 w-100">
                <CButton className="w-100 bg-danger" onClick={deleteContact}>
                  <TranslateComponent stringText="Xóa nhóm" />
                </CButton>
              </div>
            </>
          ) : (
            <></>
          )}

          {chatRoom.isGroup === 1 && chatRoom.createdBy != user?.id ? (
            <>
              <div className="p-3 w-100">
                <CButton className="w-100 bg-danger" onClick={leaveGroup}>
                  <TranslateComponent stringText="Rời nhóm" />
                </CButton>
              </div>
            </>
          ) : (
            <></>
          )}

          {chatRoom.isGroup === 0 ? (
            <>
              <div className="p-3 w-100">
                <CButton className="w-100 bg-danger" onClick={deleteContact}>
                  <TranslateComponent stringText="Xóa liên hệ" />
                </CButton>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}

      {step === 1 && <Photo chatRoom={chatRoom} />}
      {step === 2 && <FileComponet chatRoom={chatRoom} />}
      {step === 3 && <LinkComponet chatRoom={chatRoom} />}
      {step === 4 && <SetPassword chatRoom={chatRoom} />}
      <ModalAddUserToGroup
        addUser={addUser}
        setAddUser={setAddUser}
        room={chatRoom}
      />
      <ModalEditGroup
        chatRoom={chatRoom}
        editGroup={editGroup}
        setEditGroup={setEditGroup}
      />
    </CContainer>
  )
}

export default MessageInfo
